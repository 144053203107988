import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog";
import { API_ACCESS_URL, getURLParam } from "../modals/Constants";
import { getStorage, deleteStorage } from "../modals/Storage";

function AllBankCards() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
    pageTitle: "Bank Cards",
    bankCardMethod: "",
    isLoadingShow: false,
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn2") {
      if (pageConst.bankCardMethod == "crypto") {
        navigate("/addcryptoaddress?M=" + pageConst.bankCardMethod, {
          replace: false,
        });
      } else {
        navigate("/addbankcard?M=" + pageConst.bankCardMethod, {
          replace: false,
        });
      }
    }
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const setCardPrimary = (id) => {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            route: "route-set-bankcard-primary",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          getGameRecords();
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    if (id != "") {
      updateLoadingStatus(true);
      fecthApiData(
        API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&CARD_ID=" + id
      );
    }
  };

  const updateRecordList = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div
          key={i}
          className="pr-v w-100 br-5 mg-t-15 bg-l-blue br-a-l-blue"
          onClick={() => setCardPrimary(data[i]["c_bank_id"])}
        >
          <div
            className={`pd-5-15 br-5 ft-sz-16 ft-wgt-500 cl-white ${
              data[i]["c_is_primary"] == "true" ? "bg-blue" : "bg-grey-2"
            }`}
          >
            {data[i]["c_is_primary"] == "true" ? "Primary" : "Set Primary"}
          </div>
          <div
            className={`ps-ab ps-tp ps-rgt pd-5-15 br-5 ft-sz-16 cl-white bg-yellow`}
          >
            Verified
          </div>

          <div className="col-view w-100 mg-t-5 pd-5-15">
            <span className="ft-sz-15 mg-t-5">
              Name: {data[i]["c_beneficiary"]}
            </span>
            <span className="ft-sz-15 mg-t-5">
              ACC: {data[i]["c_bank_account"]}
            </span>
            <span className="ft-sz-15 mg-t-5">
              {data[i]["c_bank_ifsc_code"] == "none"
                ? "Method: UPI"
                : "IFSC: " + data[i]["c_bank_ifsc_code"]}
            </span>
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getGameRecords() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            route: "route-get-bankcards",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();
        console.log(data);
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          updateRecordList(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fecthApiData(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else if (getURLParam("M") != null && getURLParam("M") != "") {
      setConstants((previousState) => {
        return { ...previousState, bankCardMethod: getURLParam("M") };
      });

      getGameRecords();
    } else {
      navigate("/home", { replace: true });
    }
  }, []);

  return (
    <div className="v-center">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2="Add New"
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={pageConst} />

        <div className="col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15 bg-white">
            <div className="col-view pd-5-15">{pageConst.recordList}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllBankCards;
