import React from 'react'

const fastParityRuleDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg ${intentData.ruleDialogShow ? 'activeDialog' : ''}`} onClick={(e)=>{updateState(e,false)}}>
            <div className='dlg-c ps-fx ps-btm col-view a-center bg-l-grey res-wth h-350-p pd-15 ovf-scrl hide-sb'>
                <p className='ft-sz-18 mg-b-10 cl-white pd-5-15 br-20 bx-shdw-blue bg-blue'>Dice Rule</p>

                <div className='col-view pd-5 mg-b-50 w-100'>
                    <div className='ft-sz-16 cl-grey'>
                    1 minutes 1 issue, 50 seconds to order, 10 seconds to show the lottery result. It opens all day. The total number of trade is 1440 issues.
                    </div>

                    <div className='tbl-view'>
                        <div className='row-view tbl-head mg-t-10 br-a-b bg-lb'>
                            <div>
                                <p>Select</p>
                            </div>

                            <div className='br-l'>
                                <p>Result</p>
                            </div>

                            <div className='br-l'>
                                <p>You</p>
                            </div>
                        </div>

                        <div className='row-view br'>
                            <div>&#60; N</div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10 br-b'>&#60; n</p>
                                    <p className='pd-10'>&#8805; n</p>
                                </div>
                            </div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10 br-b'>Win</p>
                                    <p className='pd-10'>Loose</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='pd-10 ps-fx ps-btm res-wth bg-l-grey'>
                  <div className='bg-blue h-45-p ft-sz-15 pd-10 br-10 cl-white v-center w-100' onClick={(e)=>{updateState(e,"dismiss")}}>
                    <span>I GOT IT</span>
                  </div>
                </div>
                
            </div>
        </div>
    );
};

export default fastParityRuleDialog;