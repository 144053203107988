import React from 'react'

const fastParityRuleDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg ${intentData.ruleDialogShow ? 'activeDialog' : ''}`} onClick={(e)=>{updateState(e,false)}}>
            <div className='dlg-c ps-fx ps-btm col-view a-center bg-l-grey res-wth h-550-p pd-15 ovf-scrl hide-sb'>
                <p className='ft-sz-18 mg-b-10 cl-white pd-5-15 br-20 bx-shdw-blue bg-blue'>FastParity Rule</p>

                <div className='col-view pd-5 mg-b-50 w-100'>
                    <div className='ft-sz-16 cl-grey'>
                    3 minutes 1 issue,2 minutes 30 seconds to order,30 seconds to show the lottery result. It opens all day. The total number of trade is 480 issues.
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    If you spend 100 rupees to trade,after deducting 2 rupees service fee,your contract amount is 98 rupees:
                    </div>

                    <div className='ft-sz-14 mg-t-10 pd-l-20 cl-grey'>
                        <ul>
                            <li>JOIN GREEN:if the result shows 1,3,7,9,you will get (98*2) 196 rupees;If the result shows 5,you will get (98*1.5) 147 rupees.</li>
                            <li className='mg-t-5'>JOIN RED:if the result shows 2,4,6,8,you will get (98*2) 196 rupees;If the result shows 0,you will get (98*1.5) 147 rupees.</li>
                            <li className='mg-t-5'>JOIN VIOLET:if the result shows 0 or 5,you will get (98*4.5) 441 rupees.</li>
                        </ul>
                    </div>

                    <div className='tbl-view'>
                        <div className='row-view tbl-head mg-t-10 br-a-b bg-lb'>
                            <div>
                                <p>Select</p>
                            </div>

                            <div className='br-l'>
                                <p>Result</p>
                            </div>

                            <div className='br-l'>
                                <p>Multiplier</p>
                            </div>
                        </div>

                        <div className='row-view br-a-b'>
                            <div>Join Green</div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10 br-b'>1,3,7,9</p>
                                    <p className='pd-10'>5</p>
                                </div>
                            </div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10 br-b'>2</p>
                                    <p className='pd-10'>1.5</p>
                                </div>
                            </div>
                        </div>

                        <div className='row-view br-a-b'>
                            <div>Join Green</div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10 br-b'>1,3,7,9</p>
                                    <p className='pd-10'>5</p>
                                </div>
                            </div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10 br-b'>2</p>
                                    <p className='pd-10'>1.5</p>
                                </div>
                            </div>
                        </div>

                        <div className='row-view br-a-b'>
                            <div>Join Violet</div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10'>0,5</p>
                                </div>
                            </div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10'>4.5</p>
                                </div>
                            </div>
                        </div>

                        <div className='row-view br'>
                            <div>number</div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10'>n</p>
                                </div>
                            </div>
                            <div className='br-l'>
                                <div className='col-view'>
                                    <p className='pd-10'>9</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pd-10 ps-fx ps-btm res-wth bg-l-grey'>
                  <div className='bg-blue h-45-p ft-sz-15 pd-10 br-10 cl-white v-center w-100' onClick={(e)=>{updateState(e,"dismiss")}}>
                    <span>I GOT IT</span>
                  </div>
                </div>
                
            </div>
        </div>
    );
};

export default fastParityRuleDialog;