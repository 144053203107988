import React from 'react'
import { Link } from 'react-router-dom';

function RewardOptions(){

  const openInstagram = (data) => {
    let share_url = "https://www.instagram.com/saving_454/";
    window.open(share_url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
  }

    return (
        <div className="w-100 pd-15-0 v-center">
            
            <Link className="w-100 txt-deco-n row-view v-center" to={"/claimgiftcard"}>
              <img className='h-w-36' src={require('../icons/coupon_icon.png')} />
              <span className='mg-l-10 cl-black'>Claim Giftcard</span>
            </Link>

            <Link className="w-100 txt-deco-n row-view v-center" to={"/dailycheckin"}>
              <img className='h-w-36' src={require('../icons/task_icon.png')} />
              <span className='mg-l-10 cl-black'>Daily CheckIn</span>
            </Link>
        </div>
    );
}

export default RewardOptions;