import React, { useEffect,useState } from 'react'
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import '../../MainStyle.css';
import { API_ACCESS_URL } from '../modals/Constants';
import { Link,useNavigate } from 'react-router-dom';
import { deleteStorage, getStorage, setStorage } from '../modals/Storage';

function ClaimGiftcard(){
    const navigate = useNavigate();
    const [isInputValCorrect, setInValCorrect] = useState(false);
    const [pageConst, setConstants] = useState({
        pageTitle: "Redeem Giftcard",
        isLoadingShow: false,
        availBalance: 0,
        inGiftCardId: "",
        withdrawlMode: "W",
        toastDialogShow: false,
        toastMessage: "",
        recordList: [],
    });

    const topBarClickAction = (data) =>{
      if(data=="multiBtn1"){
        navigate('/withdraw', { replace: false });
      }else if(data=="multiBtn2"){
        navigate('/withdrawrecords', { replace: false });
      }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const onInputValChange = (source,data) =>{

      if(source=="giftcardid" && data.length >= 5){
        setInValCorrect(true);
        setConstants(previousState => {
          return { ...previousState, inGiftCardId: data }
        });
      }else{
        setInValCorrect(false);
      }
    }

    const claimRedeemCard = () =>{
      const fecthApiData = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'route': 'route-claim-giftcard',
              'AuthToken': getStorage("secret"),
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code=="code_not_exist"){
            updateToastDialogState(true,"Invalid RedeemCard!");
          }else if(data.status_code=="balance_limit"){
            updateToastDialogState(true,"Oops! You need to add more balance to claim this!");
          }else if(data.status_code=="already_applied"){
            updateToastDialogState(true,"Oops! You already claimed this RedeemCard!");
          }else if(data.status_code=="success"){
            setStorage("balance", data.account_balance);
            updateToastDialogState(true,"RedeemCard Applied!");
          }

          
        } catch (error) {
          updateLoadingStatus(false);
        }
      };

      if(isInputValCorrect && pageConst.isLoadingShow==false){
        updateLoadingStatus(true);
        const formData = { USER_ID: getStorage("uid"),GIFTCARD_ID: pageConst.inGiftCardId};
        fecthApiData(API_ACCESS_URL,formData);
      }
      
    }

    useEffect(() => {
      if (!getStorage("uid")) {
        if (deleteStorage()) {
          navigate("/login", { replace: true });
        }
      }
    }, []);

    return (
      <div className="v-center bg-black">
        <div className="h-100vh res-wth app-bg ovf-scrl-y hide-sb">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

          <div className='zoomAnimView'>
          <TopBar intentData={pageConst} multiBtn={false} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>

          <div className="col-view">
            <div className='w-100 bg-primary'>
              <img className='w-70 mg-t-80' src={require('../icons/gift_box_icon.png')} />
            </div>

            <div className='pd-10-20'>
            <div className='col-view mg-t-20'>
              <span className='ft-sz-18 cl-l-grey'>Redeem GiftCard</span>
              <div className='row-view br-b-grey'>
                <input type="text" className='cutm-inp ft-sz-20 h-60-p inp-ph-l-color' placeholder='Please Enter GiftCard' onChange={e => onInputValChange('giftcardid',e.target.value)}></input>
              </div>
            </div>
            
            <div className={`w-100 mg-t-30 h-50-p ft-sz-20 v-center br-10 mg-b-50 cl-white ${isInputValCorrect ? 'bg-btn-active' : 'bg-btn-notactive'}`} onClick={() => claimRedeemCard()}>
              <div className={`h-20-p w-20-p lodr-v ${pageConst.isLoadingShow==false ? 'hide-v' : ''}`}></div>
              <span className={`${pageConst.isLoadingShow==true ? 'hide-v' : ''}`}>Claim</span>
            </div>
            </div>

          </div>
          </div>
        </div>
      </div>
    );
}

export default ClaimGiftcard;