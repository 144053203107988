import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import { API_ACCESS_URL,getURLParam } from '../modals/Constants';
import { deleteStorage, getStorage } from '../modals/Storage';

function AddCryptoAddress(){
    const navigate = useNavigate();
    const [isInputValCorrect, setInValCorrect] = useState(false);

    const [pageConst, setConstants] = useState({
        pageTitle: "Add Crypto",
        isLoadingShow: false,
        inCryptoAddress: "",
        inCryptoName: "",
        inPrimaryOption: "true",
        bankCardMethod: "",
        recordList: [],
    });

    const topBarClickAction = (data) =>{
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const checkForInputVal = (cryptoname,cryptoaddress) =>{
        if(cryptoname.length > 2 && cryptoaddress.length > 5){
            setInValCorrect(true);
        }else{
          setInValCorrect(false);
        }
    }
  
    const onInputValChange = (source,data) =>{
        if(source=="cryptoname"){
          checkForInputVal(data, pageConst.inCryptoAddress);
  
          setConstants(previousState => {
            return { ...previousState, inCryptoName: data }
          });
        }
  
        if(source=="cryptoaddress"){
          checkForInputVal(pageConst.inCryptoName, data);
  
          setConstants(previousState => {
            return { ...previousState, inCryptoAddress: data }
          });
        }
    }

    const setPrimaryOption = (data) =>{
      setConstants(previousState => {
        return { ...previousState, inPrimaryOption: data }
    });
    }


    function AddNewBankCard(){
        const fecthApiData = async (url) => {
          try {
            const res = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'AuthToken': getStorage("secret"),
              }
            });
  
            const data = await res.json();

            updateLoadingStatus(false);

            if(data.status_code=="success"){
              navigate(-1);
            }else if(data.status_code=="already_exist"){
              updateToastDialogState(true,"Account Number already exit! Please try again!");
            }else if(data.status_code=="limit_reached"){
              updateToastDialogState(true,"Limit reached! You can't add more bankcard!");
            }else{
              updateToastDialogState(true,"Something went wrong! Please try again!");
            }
          } catch (error) {
            updateLoadingStatus(false);
          }
        };

        if(isInputValCorrect){
          updateLoadingStatus(true);
          fecthApiData(API_ACCESS_URL+"request-add-bankcard.php?USER_ID="+getStorage("uid")+"&BENEFICIARY_NAME="+pageConst.inCryptoName+"&USER_BANK_NAME=NONE"+
          "&USER_BANK_ACCOUNT="+pageConst.inCryptoAddress+"&USER_BANK_IFSC_CODE=NONE"+
          "&IS_PRIMARY="+pageConst.inPrimaryOption);
        }
    }

    useEffect(() => {
      if (!getStorage("uid")) {
        if (deleteStorage()) {
          navigate("/login", { replace: true });
        }
      }else{
        if(getURLParam('M')!=null && getURLParam('M')!=""){

          setConstants(previousState => {
            return { ...previousState, bankCardMethod: getURLParam('M') }
          });
        }
      }
    }, []);

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
          <TopBar intentData={pageConst} multiBtn={false} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

          <div className="col-view mg-t-45">

            <div className="col-view br-right-t br-left-t mg-b-15 bg-white">

               <div className='col-view'>
                  <div className='col-view w-100 pd-5-15 mg-t-20'>
                    <span className='ft-sz-13 mg-l-10'>Crypto Name:</span>
                    <input type='text' className='cutm-inp mg-t-5' placeholder='Please Input' onChange={e => onInputValChange('cryptoname',e.target.value)}></input>
                  </div>

                  <div className='line-hv-grey mg-t-5'></div>

                  <div className='col-view w-100 pd-5-15 mg-t-20'>
                    <span className='ft-sz-13 mg-l-10'>Crypto Address:</span>
                    <input type='text' className='cutm-inp mg-t-5' placeholder='Please Input' onChange={e => onInputValChange('cryptoaddress',e.target.value)}></input>
                  </div>

                  <div className='line-hv-grey mg-t-5'></div>

                  <div className='col-view w-100 pd-5-15 mg-t-20'>
                    <span className='ft-sz-13'>Set as Primary:</span>
                    <select className='cutm-inp mg-t-10'>
                      <option value="Yes" onClick={() => setPrimaryOption("true")}>Yes</option>
                      <option value="No" onClick={() => setPrimaryOption("false")}>No</option>
                    </select>
                  </div>

                  <div className='line-hv-grey mg-t-5'></div>

                  <div className='w-100 pd-5-15 mg-t-25'>
                    <div className={`w-100 h-50-p ft-sz-20 v-center br-10 cl-white bx-shdw-blk ${isInputValCorrect ? 'bg-blue' : 'bg-grey-2'}`} onClick={() => AddNewBankCard()}>
                      <img className={`smpl-btn-l spin-anim ${pageConst.isLoadingShow==false ? 'hide-v' : ''}`} src={require('../icons/loader-icon.png')} />
                      <span className={`${pageConst.isLoadingShow==true ? 'hide-v' : ''}`}>Add</span>
                    </div>
                  </div>

                </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default AddCryptoAddress;