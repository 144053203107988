import React from 'react'

const fastParityRuleDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg ${intentData.ruleDialogShow ? 'activeDialog' : ''}`} onClick={(e)=>{updateState(e,false)}}>
            <div className='dlg-c ps-fx ps-btm col-view a-center bg-l-grey res-wth h-450-p pd-15 ovf-scrl hide-sb'>
                <p className='ft-sz-18 mg-b-10 cl-white pd-5-15 br-20 bx-shdw-blue bg-blue'>Aviator Rule</p>

                <div className='col-view pd-5 mg-b-50 w-100'>
                    <div className='ft-sz-16 cl-grey'>
                    Aviator is one of the most popular games in india. 
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    The game also called the small plane game, is very easy to control. All that is required of the player is to place a bet and cash it out before the plane takes off. If the player does not do this before the plane disappears, they will lose their bet and receive no winnings.
                    </div>

                    <div className='col-view ft-sz-16 mg-t-10 cl-grey'>
                    <div className='ft-wgt-500 ft-sz-18'>How to play?</div>
                     <div className='mg-t-10'>1) On the first screen, you will see a large button labeled “Start”. Next to it, there is a field with “1.00 – +”;</div>
                     <div className='mg-t-5'>2) Click “Start” to enter the round;</div>
                     <div className='mg-t-5'>3) Finally, click “Cash Out” on the same button to close the bet.</div>
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                    If you spend ₹100 to trade, after deducting 2 rupees service fee, your contract amount is 98 rupees:
                    </div>

                </div>

                <div className='pd-10 ps-fx ps-btm res-wth bg-l-grey'>
                  <div className='bg-blue h-45-p ft-sz-15 pd-10 br-10 cl-white v-center w-100' onClick={(e)=>{updateState(e,"dismiss")}}>
                    <span>I GOT IT</span>
                  </div>
                </div>
                
            </div>
        </div>
    );
};

export default fastParityRuleDialog;