import React from 'react'

const noticeDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 v-center ${intentData.noticeDialogShow ? 'activeDialog' : ''}`} onClick={()=>updateState(false)}>
            <div className='tst-content'>
                <div className='pd-15 ft-sz-18'>{intentData.noticeTitle}</div>
                <div className='pd-15 ft-sz-14'>{intentData.noticeNote}</div>

                <div className='br-a-l-black'></div>

                <div className='v-center pd-15 ft-sz-18 cl-blue'>OK</div>
            </div>
        </div>
    );
};

export default noticeDialog;