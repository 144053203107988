import React from 'react'

const wheelocityRuleDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg ${intentData.ruleDialogShow ? 'activeDialog' : ''}`} onClick={(e)=>{updateState(e,false)}}>
            <div className='dlg-c ps-fx ps-btm col-view a-center bg-l-grey res-wth h-550-p pd-15 ovf-scrl hide-sb'>
                <p className='ft-sz-18 mg-b-10 cl-white pd-5-15 br-20 bx-shdw-blue bg-blue'>Wheelocity Rule</p>

                <div className='col-view pd-5 mg-b-50 w-100'>
                    <div className='ft-sz-16 cl-grey'>
                    The game is played every 60 seconds,and one of the 38 grids is randomly selected in each draw.The 38 squares correspond to 3 colors (yellow,red,green),three animals(tiger, elephant,cow),and 38 numbers.If you choose the right one,you will win the prize. The odds are as follows: Yellow 2X, Green 2X, Red 18X Tiger 3X, Elephant 3X, Cow 3X, Crown 18X Number 36X
                    </div>

                    <div className='ft-sz-16 mg-t-10 cl-grey'>
                        <div className='ft-sz-20 ft-wgt-500'>How is the amount calculated?</div>
                        <div className='mg-t-15'>If you spend 100 rupees to trade,after deducting 5 rupees service fee,your contract amount is 95 rupees:</div>
                        <div className='ft-sz-18 ft-wgt-500 mg-t-20'>36X:You will get (95 x 36) = 3420 rupees</div>
                    </div>

                </div>

                <div className='pd-10 ps-fx ps-btm res-wth bg-l-grey'>
                  <div className='bg-blue h-45-p ft-sz-15 pd-10 br-10 cl-white v-center w-100' onClick={(e)=>{updateState(e,"dismiss")}}>
                    <span>I GOT IT</span>
                  </div>
                </div>
                
            </div>
        </div>
    );
};

export default wheelocityRuleDialog;