import React, { useRef, useState, useEffect } from "react";
import RewardOptions from "../other-components/RewardOptions";
import { setStorage, getStorage, deleteStorage } from "../modals/Storage";
import { API_ACCESS_URL, AVATARS_ARR } from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import BottomNavbar from "../other-components/BottomNavbar";
import NoticeDialog from "../dialogs/NoticeDialog";

import SlideShow from "../other-components/SlideShow";

function Home() {
  const navigate = useNavigate();
  const [isLoadingShow, setLoadingShow] = useState(false);

  const [pageConst, setConstants] = useState({
    accountBalance: 0,
    isDataLoaded: false,
    recordList: [],
    sliderImages: [],
    winnersRecordList: [],
  });

  const [noticeDialogConst, setNoticeDialogConst] = useState({
    noticeTitle: "",
    noticeNote: "",
    noticeDialogShow: false,
  });

  const refreshData = () => {
    setConstants((previousState) => {
      return { ...previousState, isDataLoaded: false };
    });
  };

  const updateSliderImagesArr = (data) => {
    if (data.length > 0) {
      setConstants((previousState) => {
        return { ...previousState, sliderImages: data };
      });
    }
  };

  const showNotice = (data) => {
    if (data.length > 0) {
      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeTitle: data[0]["notice_title"] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeNote: data[0]["notice_note"] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeDialogShow: true };
      });
    }
  };

  const updateNoticeDialogState = (data) => {
    setNoticeDialogConst((previousState) => {
      return { ...previousState, noticeDialogShow: data };
    });
  };

  function getAccountInfo() {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-account-info",
            AuthToken: getStorage("secret"),
          },
        });
        const data = await res.json();

        setLoadingShow(false);

        if (data.status_code == "success") {
          let accountAvatarId = data.data[0]["account_avatar_id"];
          let accountUserName = data.data[0]["account_user_name"];
          let accountLevel = data.data[0]["account_level"];
          let accountBalance = data.data[0]["account_balance"];
          let accountWinningBalance = data.data[0]["account_w_balance"];
          let accountCommissionBalance = data.data[0]["account_c_balance"];
          let service_app_status = data.data[0]["service_app_status"];
          let service_min_recharge = data.data[0]["service_min_recharge"];
          let service_min_withdraw = data.data[0]["service_min_withdraw"];
          let service_recharge_option = data.data[0]["service_recharge_option"];
          let service_telegram_url = data.data[0]["service_telegram_url"];
          let service_livechat_url = data.data[0]["service_livechat_url"];
          let service_payment_url = data.data[0]["service_payment_url"];
          let service_app_download_url =
            data.data[0]["service_app_download_url"];

          setStorage("avatarId", accountAvatarId);
          setStorage("uname", accountUserName);
          setStorage("balance", accountBalance);
          setStorage("cbalance", accountCommissionBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("accountLevel", accountLevel);
          setStorage("minrecharge", service_min_recharge);
          setStorage("minwithdraw", service_min_withdraw);
          setStorage("rechargeoptions", service_recharge_option);
          setStorage("telegramURL", service_telegram_url);
          setStorage("liveChatURL", service_livechat_url);
          setStorage("appDownloadURL", service_app_download_url);
          setStorage("paymentURL", service_payment_url);
          setConstants({
            ...pageConst,
            accountBalance: accountBalance,
            isDataLoaded: true,
          });

          if (service_app_status == "OFF") {
            navigate("/um", { replace: true });
          } else {
            updateWinnersRecords();
            showNotice(data.noticeArr);
            updateSliderImagesArr(data.slideShowList);
          }
        } else if (
          data.status_code == "account_suspended" ||
          data.status_code == "authorization_error"
        ) {
          if (deleteStorage()) {
            navigate("/login", { replace: true });
          }
        }
      } catch (error) {
        setLoadingShow(false);
      }
    };

    if (!isLoadingShow && !pageConst.isDataLoaded) {
      setLoadingShow(true);
      fecthApiData(API_ACCESS_URL + "?USER_ID=" + getStorage("uid"));
    }
  }

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  const updateWinnersRecords = () => {
    let tempData = [];

    for (let i = 0; i < 26; i++) {
      let randNum = getRandomNum(105, 905, "");
      let randWinAmount = getRandomNum(10, 15000, "");

      function getRandomNumber(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
      }

      let randomNumber = getRandomNumber(0, AVATARS_ARR.length - 1);

      tempData.push(
        <div key={randWinAmount} className="v-center mg-r-50">
          <div className="h-w-20 ovf-hidden br-50 mg-l-15">
            <img className="h-20-p" src={AVATARS_ARR[randomNumber].avatarURL} />
          </div>
          <div className="mg-l-5">******{randNum} Wins</div>
          <span className="mg-l-5 cl-green">{randWinAmount} rs</span>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, winnersRecordList: tempData };
    });
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else {
      getAccountInfo();
    }
  }, [pageConst.sliderImages]);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh res-wth bg-white ovf-scrl-y hide-sb">
        <NoticeDialog
          intentData={noticeDialogConst}
          updateState={updateNoticeDialogState}
        />

        <div className="ps-rl h-40-p w-100 leaderboard-view row-view bg-l-blue ovf-hidden">
          <div className="row-view container white-sp-nw">
            {pageConst.winnersRecordList}
          </div>
        </div>

        <div className="zoomAnimView">
          <div className="row-view sb-view mg-t-25 pd-15">
            <div className="col-view mg-l-15">
              <p>Balance</p>
              <div className="row-view a-center">
                <span className="ft-sz-25 ft-wgt-500">
                  ₹{pageConst.accountBalance}
                </span>
                <img
                  className={`h-20-p mg-l-10 ${
                    isLoadingShow ? "spin-anim" : ""
                  }`}
                  src={require("../icons/refresh_icon.png")}
                  onClick={() => refreshData()}
                />
              </div>
              <div className="ft-sz-16 cl-l-grey mg-t-10">
                ID:{getStorage("uid")}
              </div>
            </div>

            <div className="col-view">
              <Link
                className="v-center txt-deco-n pd-10-15 br-5 ft-sz-15 cl-white ft-wgt-500 bg-blue"
                to={"/recharge"}
              >
                Deposit
              </Link>
              <Link
                className="v-center txt-deco-n pd-10-15 br-5 ft-sz-15 cl-black ft-wgt-500 bg-grey mg-t-10"
                to={"/withdraw?M=W"}
              >
                Withdraw
              </Link>
            </div>
          </div>

          <RewardOptions />

          <SlideShow dataList={pageConst.sliderImages} />

          <div className="available-games-view">
            <Link to={"/FastParity"}>
              <img
                className="w-100 br-8"
                src={require("../icons/fast_parity_banner.jpg")}
              />
            </Link>

            <Link to={"/Spare"}>
              <img
                className="w-100 br-8"
                src={require("../icons/spare_banner.jpg")}
              />
            </Link>

            <Link to={"/Parity"}>
              <img
                className="w-100 br-8"
                src={require("../icons/parity_banner.jpg")}
              />
            </Link>

            <Link to={"/AndarBahar"}>
              <img
                className="w-100 br-8"
                src={require("../icons/andar_bahar_banner.jpg")}
              />
            </Link>

            <Link to={"/Aviator"}>
              <img
                className="w-100 br-8"
                src={require("../icons/jet_banner.png")}
              />
            </Link>

            <Link to={"/Wheelocity"}>
              <img
                className="w-100 br-8"
                src={require("../icons/wheelocity_banner.png")}
              />
            </Link>

            <Link to={"/GoldMiner"}>
              <img
                className="w-100 br-8"
                src={require("../icons/goldminer_banner.png")}
              />
            </Link>

            <Link to={"/DragonTiger"}>
              <img
                className="w-100 br-8"
                src={require("../icons/dragontiger_banner.jpg")}
              />
            </Link>
          </div>
        </div>

        <BottomNavbar activeBar="home" />
      </div>
    </div>
  );
}

export default Home;
