import React, { useEffect, useState } from "react";
import BottomNavbar from "../other-components/BottomNavbar";
import LoadingDialog from "../dialogs/LoadingDialog";
import { Link, useNavigate } from "react-router-dom";
import { deleteStorage, getStorage } from "../modals/Storage";
import { WEBSITE_NAME, openNewPage } from "../modals/Constants";

function Recharge() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
    pageTitle: "Recharge",
    isLoadingShow: false,
    inRechargeAmnt: "",
    recordList: [],
  });

  const onInputValChange = (source, data) => {
    console.log(data);

    if (source == "rechargeamount") {
      setConstants((previousState) => {
        return { ...previousState, inRechargeAmnt: data };
      });
    }
  };

  const rechargeNow = () => {
    if (
      getStorage("paymentURL") &&
      Number(pageConst.inRechargeAmnt) >= getStorage("minrecharge")
    ) {
      openNewPage(
        getStorage("paymentURL") +
          "?am=" +
          pageConst.inRechargeAmnt +
          "&crudentials=" +
          getStorage("uid")
      );
    }
  };

  const setAllRechargeOptions = () => {
    let tempData = [];
    const recentBetArr = getStorage("rechargeoptions").split(",");

    for (let i = 0; i < recentBetArr.length; i++) {
      tempData.push(
        <div
          key={i}
          className="pd-10-15 v-center br-5 ft-sz-16 bg-l-blue"
          onClick={() => onInputValChange("rechargeamount", recentBetArr[i])}
        >
          ₹{recentBetArr[i]}
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else {
      setAllRechargeOptions();
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb app-bg">
        <LoadingDialog intentData={pageConst} />

        <div className="zoomAnimView">
          <div className="col-view pd-10-20 mg-t-10 mg-b-70">
            <div className="w-100 row-view sb-view a-flex-start">
              <div className="col-view">
                <span>Balance</span>
                <span className="ft-sz-25 ft-wgt-500">
                  ₹{getStorage("balance")}
                </span>
              </div>

              <Link
                className="txt-deco-n cl-black row-view"
                to={"/rechargerecords"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path d="M8 .5a7.5 7.5 0 1 1 0 15l-.054-.002c-1.95-.155-3.531-.812-4.718-1.96v1.508H1.863v-4.319h3.863v1.364H3.75c.986 1.213 2.397 1.89 4.278 2.045l.142-.002A6.137 6.137 0 1 0 1.864 8H.5A7.5 7.5 0 0 1 8 .5Zm-.91 3.41h1.365v3.408h3.409v1.364H7.09V3.909Z"></path>
                </svg>
                <div className="mg-l-10">Records</div>
              </Link>
            </div>

            <div className="pd-10 mg-t-20 br-10 bg-l-green">
              <div className="w-100 row-view v-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="31"
                  fill="none"
                >
                  <path
                    fill="#C2F8E0"
                    d="M25.863 4.29C18.103 5.097 13 .302 13 .302S7.892 5.097.136 4.29c0 0-2.394 21.64 12.863 25.941 15.26-4.3 12.864-25.94 12.864-25.94Z"
                  ></path>
                  <path
                    fill="#6CC8EC"
                    d="m13.07 4.86-.003.001v21.361h.003c8.464-2.892 9.314-13.648 9.27-18.322-4.215-.167-7.454-1.833-9.27-3.042v.002Z"
                  ></path>
                  <path
                    fill="#00C46F"
                    d="m23.6 7.02-.004-.252-.252-.01c-4.54-.18-8.053-1.92-10.198-3.347l-.147-.098-.147.098C10.704 4.84 7.19 6.58 2.65 6.76l-.252.01-.003.252c-.06 5.118.847 17.38 10.519 20.681l.086.028.086-.028c9.635-3.294 10.563-15.56 10.514-20.68V7.02Z"
                  ></path>
                  <path
                    fill="#00AA5D"
                    d="M13.085 27.7C22.721 24.407 23.65 12.14 23.6 7.02l-.003-.252-.252-.01c-4.541-.18-8.053-1.92-10.199-3.347L13 3.313V27.73l.086-.028V27.7Z"
                  ></path>
                  <path
                    fill="#008445"
                    d="m7.07 15.576 2.066-2.067 3.622 3.616 6.198-6.196 2.068 2.066-8.264 8.264-5.69-5.683Z"
                  ></path>
                  <path
                    fill="#fff"
                    d="m6.497 15.004 2.065-2.069 3.624 3.618 6.198-6.197 2.069 2.066-8.266 8.264-5.69-5.682Z"
                  ></path>
                </svg>
                <div className="mg-l-10 ft-sz-15 ft-wgt-500">
                  Payment Secured By SuperTech
                </div>
              </div>
              <div className="ft-sz-13 mg-t-10">
                {WEBSITE_NAME} only offers in-platform deposit channels. Please
                do not trust anyone impersonating {WEBSITE_NAME} customer
                service offering agent-based deposits!
              </div>
            </div>

            <div className="col-view mg-t-25">
              <span className="ft-sz-18 ft-wgt-500 cl-grey">Amount</span>
              <div className="row-view br-b-grey">
                <span className="cl-l-grey ft-sz-20">Rs</span>
                <input
                  type="number"
                  className="cutm-inp ft-sz-38 h-60-p inp-ph-l-color"
                  value={pageConst.inRechargeAmnt}
                  placeholder={`${getStorage("minrecharge")} ~ 70000`}
                  onChange={(e) =>
                    onInputValChange("rechargeamount", e.target.value)
                  }
                ></input>
              </div>
            </div>

            <div className="w-100 g-v-3 mg-t-25">{pageConst.recordList}</div>

            <div
              className="w-100 mg-t-30 h-50-p ft-sz-20 v-center br-10 cl-white bg-btn-active"
              onClick={() => rechargeNow()}
            >
              <img
                className={`smpl-btn-l spin-anim ${
                  pageConst.isLoadingShow == false ? "hide-v" : ""
                }`}
                src={require("../icons/loader-icon.png")}
              />
              <span
                className={`${pageConst.isLoadingShow == true ? "hide-v" : ""}`}
              >
                Recharge
              </span>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="recharge" />
      </div>
    </div>
  );
}

export default Recharge;
