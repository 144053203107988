import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import ToastDialog from "../dialogs/ToastDialog";
import { deleteStorage, getStorage } from "../modals/Storage";
import "../../MainStyle.css";

function Privilege() {
  const navigate = useNavigate();

  const [pageConst, setConstants] = useState({
    pageTitle: "Privilege",
    isLoadingShow: false,
    toastDialogShow: false,
    toastMessage: "",
    recordList: [],
  });

  const topBarClickAction = (data) => {};

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh res-wth bg-white">
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />

        <div className="pr-v ovf-scrl-y hide-sb zoomAnimView">
          <TopBar
            intentData={pageConst}
            multiBtn={true}
            multiBtn1=""
            multiBtn2=""
            updateState={topBarClickAction}
          />

          <div className="col-view mg-b-15">
            <div className="col-view v-center w-100 pd-5-15 mg-t-45">
              <div className={`col-view w-100 min-h`}>
                <span className="ft-sz-20 mg-t-10">Invite Bonus</span>
                <span className="ft-sz-15 mg-t-10">
                  You will get the bonus when you refer a person and they
                  complete first recharge.
                </span>

                <div
                  className="row-view sb-view br-right-t br-left-t mg-t-20 pd-10-15 cl-white"
                  style={{ background: "#6F6F6F" }}
                >
                  <span>Deposit</span>
                  <span>Bonus</span>
                </div>

                {/* <div className="col-view pd-10-15">
                  <div className="row-view mg-t-15 sb-view">
                    <span className="ft-sz-16 h-80-p br-50 v-center">₹200</span>
                    <span className="ft-sz-16 h-80-p br-50 v-center">₹25</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className="ft-sz-16 h-80-p br-50 v-center">₹400</span>
                    <span className="ft-sz-16 h-80-p br-50 v-center">₹50</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className="ft-sz-16 h-80-p br-50 v-center">
                      ₹1000
                    </span>
                    <span className="ft-sz-16 h-80-p br-50 v-center">₹110</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className="ft-sz-16 h-80-p br-50 v-center">
                      ₹2000
                    </span>
                    <span className="ft-sz-16 h-80-p br-50 v-center">₹190</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className="ft-sz-16 h-80-p br-50 v-center">
                      ₹10000
                    </span>
                    <span className="ft-sz-16 h-80-p br-50 v-center">₹490</span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className="ft-sz-16 h-80-p br-50 v-center">
                      ₹25000
                    </span>
                    <span className="ft-sz-16 h-80-p br-50 v-center">
                      ₹1250
                    </span>
                  </div>

                  <div className="row-view mg-t-15 sb-view">
                    <span className="ft-sz-16 h-80-p br-50 v-center">
                      ₹120000
                    </span>
                    <span className="ft-sz-16 h-80-p br-50 v-center">
                      ₹5680
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privilege;
