import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog";
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import { deleteStorage, getStorage } from "../modals/Storage";

function AllMembers() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
    pageTitle: "All Members",
    isLoadingShow: false,
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setConstants((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const updateRecordList = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      tempData.push(
        <div key={i} className="row-view sb-view pd-10-15 mg-t-15 br-b-l-grey">
          <div className="row-view">
            <div className="v-center h-w-32 ft-sz-20 cl-black br-50 bg-l-grey">
              {data[i]["m_account_level"]}
            </div>

            <div className="col-view mg-l-20">
              <span className="ft-sz-17">ID: {data[i]["m_id"]}</span>
              <span className="ft-sz-14 mg-t-5">
                Mobile: {data[i]["m_mobile"]}
              </span>
              <span className="ft-sz-12 mg-t-5">{data[i]["m_joined"]}</span>
            </div>
          </div>

          <span className="ft-sz-17 ft-wgt-500 cl-green">
            {data[i]["m_account_type"]}
          </span>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getAllMembers() {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-allmember-records",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          updateRecordList(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    requestAPI(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else {
      getAllMembers();
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={pageConst} />

        <div className="col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view pd-5-15">
              <div className="col-view">{pageConst.recordList}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllMembers;
